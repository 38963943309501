
import { defineComponent, ref } from 'vue';
import BaseOtp from '@/components/Base/BaseOTP.vue';
import BaseButton from '@/components/Base/BaseButton.vue';
// import BaseDial from '@/components/Base/BaseDial.vue';
import BaseTextError from '@/components/Base/BaseTextError.vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import BackBtn from '@/components/BackBtn.vue';
import constRouter from '@/constants/constRouter';
import { verifyOTP } from '@/apis/ApiAuth';
import { mapGetters, mapActions } from 'vuex';
import { setAuthToken, setRefreshToken } from '@/helpers/localStorage';
import * as Analytics from '@/services/analytics';

export default defineComponent({
  components: {
    BaseButton,
    BaseOtp,
    // BaseDial,
    BaseTextError,
    DefaultLayout,
    BackBtn,
  },
  name: 'RegisterPhone',
  setup() {
    const data = ref([]) as any;
    const numberOTP = ref('');
    const labelError = ref('');
    const loading = ref(false);
    const userAgent = ref(navigator.userAgent);
    return {
      LENGTH_OTP: 4,
      data,
      numberOTP,
      labelError,
      userAgent,
      loading,
    };
  },
  computed: {
    ...mapGetters({
      phoneNumber: 'getPhone',
      campaign: 'getCampaign',
    }),
  },
  mounted() {
    for (let i = 0; i < this.LENGTH_OTP; i++) {
      this.data.push(null);
    }
  },
  watch: {
    numberOTP(value) {
      if (value.length === this.LENGTH_OTP) {
        this.handleSubmit();
      }
      this.data = value.split('');
      while (this.data.length < this.LENGTH_OTP) {
        this.data.push(null);
      }
    },
  },
  methods: {
    ...mapActions({
      fetchUserInfo: 'fetchUserInfo',
    }),
    handleSubmit() {
      if (this.numberOTP.length < this.LENGTH_OTP) {
        this.labelError = 'Mã OTP chưa đủ 4 chữ số';
      } else {
        this.loading = true;

        const { utmSource, utmCampaign, utmContent, clickId } = this.campaign;
        verifyOTP({
          phoneNumber: this.phoneNumber,
          pinCode: this.numberOTP,
          deviceName: this.userAgent,
          utmSource,
          utmCampaign,
          utmContent,
          clickId,
        })
          .then((res: any) => {
            if (res.statusCode === 200) {
              setAuthToken(res.data.accessToken);
              setRefreshToken(res.data.refreshToken);
              this.fetchUserInfo();
              this.loading = false;
              this.$router.push({
                name: constRouter.SURVEY_TEST.name,
                // name: constRouter.DASHBOARD.name,
              });
            }
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
            this.labelError = err.data.message;
          });
        //analytics
        Analytics.eventK2A('REGISTER', 'INPUT_OTP', 'Nhập OTP thành công', '');
      }
    },
    inputOTP(num: number) {
      if (this.numberOTP.length === this.LENGTH_OTP) {
        this.handleSubmit();
      }
      if (this.numberOTP.length < this.LENGTH_OTP) {
        this.numberOTP = this.numberOTP + num;
      } else {
        return;
      }
    },
    clearOTP() {
      this.numberOTP = this.numberOTP.slice(0, -1);
    },
  },
});
