<template>
  <div class="input-otp relative mx-auto">
    <label>
      <input
        :value="modelValue"
        :disabled="disabled"
        ref="input"
        autofocus
        type="text"
        mode="numeric"
        :maxlength="lengthOTP"
        :size="lengthOTP"
        :class="{
          'hide-input': modelValue.length > 0,
        }"
        class="
          z-10
          px-10
          absolute
          top-1/2
          transform
          -translate-y-1/2
          left-0
          w-full
          focus:outline-none
          bg-transparent
          colors.transparent
        "
        @keyup="handleChange"
        @input="handleChange"
        @focus="handleFocus"
        inputmode="numeric"
      />
    </label>
    <div class="px-6 list-otp flex align-middle justify-between mx-auto">
      <div
        class="single-otp relative"
        v-for="(item, i) in data"
        :key="i"
        :class="{
          active: item !== null,
        }"
      >
        {{ item }}
        <p class="absolute bottom-3 text-gray-400">_</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['update:modelValue', 'focus'],
  name: 'BaseInputOTP',
  props: {
    modelValue: {
      type: String,
    },
    data: {
      type: Array,
    },
    separator: {
      type: String,
    },
    focus: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    inputClasses: {
      type: String,
    },
    shouldAutoFocus: {
      type: Boolean,
    },
    inputType: {
      type: String,
      default() {
        return 'tel';
      },
    },
    isLastChild: {
      type: Boolean,
    },
    lengthOTP: {
      type: Number,
      default: 4,
    },
  },
  mounted() {
    this.$refs['input'].focus();
  },
  methods: {
    handleChange(e) {
      if (e.target.value.length > this.lengthOTP) {
        e.target.value = e.target.value.slice(0, this.lengthOTP);
      }
      this.$emit('update:modelValue', e.target.value);
    },
    handleFocus() {
      this.$emit('focus');
    },
  },
};
</script>

<style lang="scss" scoped>
.input-otp {
  .hide-input {
    color: transparent;
    letter-spacing: 85px;
    height: 32px;
  }

  .list-otp {
    .single-otp {
      font-size: 20px;
      width: 45px;
      height: 60px;
      background-color: #fff;
      border-radius: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      &.active {
        background-color: #fff;
        box-shadow: 0px 20px 40px rgba(55, 62, 125, 0.1);
      }
    }

    .single-otp:last-child {
      margin-right: 0;
    }
  }
}
</style>
